.solar-panel-system .chart {
  margin-top: 18px;
}

.best::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: var(--green-best-color);
  position: absolute;
  right: -14px;
  top: 9px;
}

.system-properties li {
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.system-properties details {
  flex: 1;
}
.system-properties details summary::marker {
  transform: scale(1.5);
}

.system-properties details summary {
  cursor: pointer;
  text-align: left;
  padding: 4px 4px;
  background-color: hsl(220, 13%, 25%);
}

.system-properties details summary:focus-visible {
  outline: none;
  background-color: hsl(61, 89%, 40%);
  color: hsl(200, 19%, 18%);
}
