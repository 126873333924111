@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;600;700&display=swap");
:root {
  --green-best-color: hsl(120, 35%, 53%);
  --text-color: hsl(222, 28%, 91%);
  --background-color: hsl(220, 13%, 18%);
  --production-color-low: hsl(0, 69%, 60%);
  --production-color-medium: hsl(61, 89%, 46%);
  --production-color-high: hsl(120, 35%, 53%);
  --production-color-turbo: hsl(203, 98%, 59%);
  --person-color-nikhil: rgb(255, 0, 0);
  --person-color-mickey: rgb(85, 191, 59);
  --person-color-jk: rgb(218, 222, 13);
}

body {
  margin: 0;
  font-family: "Roboto", "Source Sans Pro", "Fira Sans", "Helvetica Neue",
    sans-serif;
}

a,
a:link,
a:visited {
  color: var(--text-color);
  border-radius: 2px;
}
a:hover,
a:focus {
  background-color: var(--text-color);
  color: var(--background-color);
  outline: 2px solid var(--text-color);
  outline-offset: 0;
}

html {
  background-color: var(--background-color);
  color: var(--text-color);
}

h1 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.App {
  text-align: center;
}

ul,
ul li {
  padding: 0;
  list-style-type: none;
  position: relative;
}

ul {
  width: 300px;
  margin: 0 auto;
}

ul li strong {
  text-align: right;
}

.stats-link {
  display: flex;
  gap: 16px;
  position: absolute;
  top: 0;
  font-size: 20px;
  padding: 15px;
  padding-bottom: 0;
}

.stale {
  opacity: 0.4;
}
