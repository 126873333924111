.settings {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 20px;
  cursor: pointer;
  z-index: 100;
  padding: 15px;
  padding-bottom: 0;
}

.settings-modal {
  background: #3c4048;
  text-align: center;
  padding: 10px 10px 30px 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.settings-modal .setting-container .setting {
  margin: 35px 0;
}

@media screen and (min-width: 768px) {
  .settings-modal .setting-container {
    display: flex;
    justify-content: center;
    gap: 100px;
  }

  .settings-modal .setting-container .setting {
    margin: 0;
  }
}

.settings-modal .order-list {
  margin: 0 auto;
  width: 200px;
}

.settings-modal .order-list > div {
  display: grid;
  grid-template-columns: 120px 40px;
  gap: 10px;
}

.settings-modal .order-list input {
  width: 100%;
  text-align: center;
}

.settings-modal .custom-properties-list li {
  display: grid;
  grid-template-columns: 200px 58px;
  gap: 20px;
  margin: 8px 0;
}

.settings-modal .custom-properties-list li span {
  text-align: left;
}
