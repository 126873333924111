.weather {
  align-items: center;
  height: 55px;
  text-align: center;
  display: grid;
  grid-template-columns: 75px 90px 40px 95px;
}
.weather img {
  width: 50px;
  margin-left: 10px;
}

.weather .wind-bearing span {
  display: inline-block;
}
