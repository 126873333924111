.systems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4vw;
}

.collectionsDayChart {
  display: flex;
  flex-direction: column;
  gap: 4vw;
  padding: 0 4vw;
}

.home {
  display: flex;
  flex-direction: column;
  gap: 4vw;
  max-width: 1440px;
  margin: 0 auto;
}
